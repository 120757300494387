import { useEffect } from "react";

import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { useCheckoutCartItems, useCheckoutStore } from "./store";

const useUpdateCartStock = () => {
  const { setCartItemsStock } = useCheckoutStore();
  const items = useCheckoutCartItems();
  const formattedInput = items.map(
    ({ product: { id: productId }, variant: { id: variantId } }) => ({
      productId,
      variantId,
    }),
  );

  const {
    data,
    refetch: updateCartStock,
    isFetching,
  } = appTRPC.products.getStock.useQuery(formattedInput, { enabled: false });

  useEffect(() => {
    if (data) {
      setCartItemsStock(data);
    }
  }, [data, setCartItemsStock]);

  return { updateCartStock, isFetching };
};

export default useUpdateCartStock;
