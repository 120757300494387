import { useEffect } from "react";
import { isNumber, some } from "lodash";

import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { useCheckoutCartItems, useCheckoutStore } from "./store";

export type CartConflict = "out_of_stock" | "out_of_catalog";

type Props = {
  onFinish: (conflicts: CartConflict[]) => void;
};

const useUpdateCart = ({ onFinish }: Props) => {
  const { setCartItems, removeCartItem } = useCheckoutStore();
  const items = useCheckoutCartItems();
  const productIds = items.map((item) => item.product.id);

  const {
    data: productsData,
    refetch: updateCart,
    isFetching: isUpdatingCart,
    isError: isUpdateCartError,
  } = appTRPC.products.getProductsByIds.useQuery(productIds, {
    enabled: false,
  });

  useEffect(() => {
    if (productsData) {
      const conflicts: CartConflict[] = [];

      // Remove no longer existing items from cart
      items.forEach((item) => {
        if (item.variant) {
          const isProductStillAvailable = some(productsData, {
            variants: [{ id: item.variant.id }],
          });

          if (!isProductStillAvailable) {
            removeCartItem(item.variant.id);
            conflicts.push("out_of_catalog");
          }
        }
      });

      // Update existing cart items
      const formattedData = productsData.map((product, i) => {
        const item = items.find((item) => item.product.id === product?.id);

        const orderQuantity = item?.quantity || 0;
        const currentStock = product?.variants?.[i]?.stock;
        const isLimitedStock = isNumber(currentStock);

        if (isLimitedStock && currentStock < orderQuantity) {
          conflicts.push("out_of_stock");
        }

        return {
          variantId: product?.variants?.[i]?.id,
          stock: product?.variants?.[i]?.stock,
          price: product?.variants?.[i]?.price,
          name: product?.name,
        };
      });

      if (conflicts.length > 0) {
        onFinish(conflicts);
      }

      setCartItems(formattedData);
    }
  }, [productsData, items, removeCartItem, setCartItems, onFinish]);

  return { updateCart, isUpdatingCart, isUpdateCartError };
};

export default useUpdateCart;
