import { useDebounceValue } from "usehooks-ts";

function useDebounce<T>(
  value: T,
  delay?: number,
  options?: { leading?: boolean },
): T {
  const [debouncedValue] = useDebounceValue(value, delay ?? 500, options);

  return debouncedValue;
}

export default useDebounce;
