"use client";

import React, { createContext, useContext } from "react";
import type { ReactNode } from "react";
import ms from "ms";

import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { useCanPerformSecuredApiCalls } from "~/hooks";
import { type StoreInfo } from "~/types";

const StoreInfoContext = createContext<StoreInfo | undefined>(undefined);

const useStoreInfo = () => useContext(StoreInfoContext);

interface Props {
  children: ReactNode;
  initialStoreInfo?: StoreInfo;
}

function StoreInfoProvider({ children, initialStoreInfo }: Readonly<Props>) {
  const canPerformSecuredApiCalls = useCanPerformSecuredApiCalls();

  const { data } = appTRPC.store.getStoreInfo.useQuery(undefined, {
    enabled: canPerformSecuredApiCalls,
    initialData: initialStoreInfo,
    staleTime: ms("5 seconds"),
  });

  return (
    <StoreInfoContext.Provider value={data}>
      {children}
    </StoreInfoContext.Provider>
  );
}

export { StoreInfoProvider, useStoreInfo };
