import { type Logger as WinstonLogger } from "winston";

const IS_DEV = process.env.NODE_ENV !== "production";

/**
 * NOSONAR TODO: this type is incorrect for `edge` runtime
 * We should either:
 *  - Have separate loggers (one for `nodejs`, another for `edge`)
 *  - Use `Logger | Console` as type and fix resulting errors (e.g. `.child()` method does not exist in Console)
 */
type Logger = Pick<
  WinstonLogger,
  "info" | "debug" | "warn" | "error" | "child"
>;

const logger: Logger = (() => {
  return process.env.NEXT_RUNTIME === "nodejs"
    ? (() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const winston = require("winston");

        return winston.createLogger({
          level: IS_DEV ? "debug" : "info",
          format: IS_DEV
            ? winston.format.combine(
                winston.format.colorize(),
                winston.format.simple(),
              )
            : winston.format.json(),
          transports: [new winston.transports.Console()],
        });
      })()
    : console;
})();

export { type Logger };

export default logger;
